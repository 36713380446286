import React, { Component } from 'react'
import TeacherDashboard from './TeacherDashboard'
import CreateProject from './CreateProject'
import ProjectDetail from './ProjectDetail'
import EnrolledStudents from './EnrolledStudents'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { API_URL } from '../Constants'
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Alert from 'react-bootstrap/Alert'




class CourseDetail extends Component {


    constructor(props) {
        super(props)


        this.state = {

            showResultsAdd: false,
            showResultsDelete: false,
            showResultsUpdate: false,
            showResultsManage: true

        }

        this.onClick = this.onClick.bind(this);
        this.onClickUpdate = this.onClickUpdate.bind(this);
        this.onClickManage = this.onClickManage.bind(this);

        this.onClickDelete = this.onClickDelete.bind(this);


    }

    onClick() {
        this.setState({ showResultsAdd: true, showResultsUpdate: false, showResultsDelete: false, showResultsManage: false });
    }
    onClickUpdate() {
        this.setState({ showResultsUpdate: true, showResultsDelete: false, showResultsAdd: false, showResultsManage: false });
    }
    onClickManage() {
        this.setState({ showResultsUpdate: false, showResultsDelete: false, showResultsAdd: false, showResultsManage: true });
    }
    onClickDelete() {
        this.setState({ showResultsDelete: true, showResultsUpdate: false, showResultsAdd: false, showResultsManage: false });
    }

    deleteModal = () => {
        return <Modal centered show={this.state.deleteModal} onHide={() => this.setState({ deleteModal: false })}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Class</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this class? This cannot be undone</p>

                <p><center>Class: {sessionStorage.coursename}</center></p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={() => this.setState({ deleteModal: false })}>No</Button>
                <Button variant="primary" onClick={this.deleteClass}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    }

    deleteClass = async (e) => {
        e.preventDefault();

        axios.defaults.withCredentials = true;
        console.log(this.state);

        try {

            const results = await axios.delete(API_URL + '/course/delete/' + this.state.classId);

            this.props.history.push('/teacherdashboard');

            this.setState({ deleteModal: false, classname: "", classId: "", showDeleteSuccess: true });

        } catch (error) {
            alert(error);
        }
        console.log(sessionStorage);
    }
    

    render() {

        return (
            <div className="container pt-4">
                 {this.deleteModal()}
                <div className="p-4" style={{ backgroundColor: "white", opacity: 1, filter: "Alpha(opacity=50)", borderRadius: '10px' }}>
                    <h3>&nbsp;&nbsp; Class: {sessionStorage.coursename}</h3>

                    <div className="container mt-4">
                        <Tab.Container defaultActiveKey="first">
                            <Row>
                                <Col sm={6}>
                                    <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                            <Nav.Link eventKey="third">Create Project</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Projects List</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Enrolled Students</Nav.Link>
                                        </Nav.Item>
                                       
                                    </Nav>
                                </Col>
                            </Row>
                            <Row>
                                <div className="mt-4 w-100" style={{ backgroundColor: "lightblue", opacity: .9, filter: "Alpha(opacity=50)", borderRadius: '10px' }}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first"><ProjectDetail /></Tab.Pane>
                                        <Tab.Pane eventKey="second"><EnrolledStudents /></Tab.Pane>
                                        <Tab.Pane eventKey="third"><CreateProject /></Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Row>
                        </Tab.Container>
                    </div>
                    {/* <br />
                        &nbsp;&nbsp;
                <button className="btn btn-primary" type="button" onClick={this.onClick} >Enrolled Students</button>
                        &nbsp;&nbsp;
                <button className="btn btn-primary" type="button" onClick={this.onClickUpdate} >Create Project</button>
                        &nbsp;&nbsp;
                <button className="btn btn-primary" type="button" onClick={this.onClickManage} >Project Detail</button>
                        &nbsp;&nbsp; */}
                    <div className="course-bottom">
                        <Button variant="outline-danger" onClick={e => this.setState({ deleteModal: true, classId: sessionStorage.courseid, classname: sessionStorage.coursename })}><FontAwesomeIcon icon={faTrashAlt} /> Delete Class</Button>    
                    </div>  
                    
                </div>
                {/* <div className="mt-4 " style={{ backgroundColor: "lightblue", opacity: .9, filter: "Alpha(opacity=50)", borderRadius: '10px' }}>

                    {this.state.showResultsDelete ? <TeacherDashboard /> : null}
                    {this.state.showResultsAdd ? <EnrolledStudents /> : null}
                    {this.state.showResultsUpdate ? <CreateProject /> : null}
                    {this.state.showResultsManage ? <ProjectDetail /> : null}

                </div> */}
                
            </div>

        )
    }

}

export default CourseDetail